import { useAppSelector } from "src/redux/hooks";

const OwnBrandIcon = () => {
  const iconUrl = useAppSelector(s => s.retailChain.data.logo.own_brand_gamification);

  return (
    <img src={iconUrl} className='OwnBrandIcon' width={24} height={24} alt='own-gamification' />
  );
};

export default OwnBrandIcon;
